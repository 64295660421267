import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

export const Home = () => {
    const url = window.location.href.includes("test.rscyou.in") ? "/SVSRegistrations" : 'https://localhost:7095/SVSRegistrations';
    let RegisterEmptyObject = { data: null, success: false, message: "" };
    const [appointmentData, setAppointmentData] = useState(RegisterEmptyObject);
    useEffect(() => {
        axios.get(url).then((response) => {
        //axios.get(`${url}SVSRegistrations/`).then((response) => {
            setAppointmentData(response.data);
        });
    }, []);

    return (
        <>
            <h1>Hello, Farmers!</h1>
            <h4>Welcome Farmer Registration Form SVS</h4>
            <h5>Total Registration under village seed scheme are
                {appointmentData.success == false ? (<h2>Loading.....</h2>) : 
                    (<span className="text-success badge"><b><h5> {appointmentData.data.length}</h5></b></span>)}
                </h5>
            <br />
            <hr />
            <div class="alert alert-danger" role="alert"><h2> पंजीकरण बंद कर दिया गया है। बीज समाप्त हो गए हैं।</h2></div>
            <hr />
            <div className="alert alert-primary" role="alert">
                गेहूं का दाम - 888/- रुपये प्रति बोरी
            </div>
            <div className="alert alert-secondary" role="alert">
                सरसों का दाम - 164/- रुपये प्रति 2 किलो
            </div>
            <div className="alert alert-success" role="alert">
                चना का दाम - 555/- रुपये प्रति 15 किग्रा
            </div>
            
          
            <Link className='btn btn-info btn-lg text-white' to= '/scscp-registration' >Click here for start your registration</Link>
        </>);
}
